import React from 'react';
import style from './hero-container.module.scss';

export interface Props {
  title: string;
}

const HeroContainer: React.FC<Props> = (props) => {
  return (
    <div className={style.HeroContainer}>
      <h2 className={style.title}>{props.title}</h2>
      {props.children}
    </div>
  );
};

export default HeroContainer;
