import React from 'react';
import FluidImage from '~/components/common/FluidImage/FluidImage';
import Layout from '~/components/layout/layout/layout';
import SEO from '~/components/common/SEO/SEO';
import TeamSection from '~/components/pages/team/TeamSection';
import HeroContainer from '~/components/layout/hero-container/hero-container';

const TeamPage = () => {
  return (
    <Layout>
      <SEO title="Team" />
      <HeroContainer title="Team">
        <FluidImage imageName="p01-02.jpg" />
      </HeroContainer>
      <TeamSection />
    </Layout>
  );
};

export default TeamPage;
