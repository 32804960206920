import React from 'react';
import cls from 'classnames';
import style from './section.module.scss';

export interface Props {
  className?: string;
  innerClassName?: string;
  size?: 'md' | 'sm' | 'xs';
}

const Section: React.FC<Props> = (props) => {
  return (
    <article className={cls(style.Section, props.className)}>
      <section
        className={cls(style.sectionContent, props.size, props.innerClassName)}
      >
        {props.children}
      </section>
    </article>
  );
};

export default Section;
