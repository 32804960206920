import React from 'react';
import OutLink from '~/components/common/OutLink/OutLink';
import { useSiteMetadata } from '~/hooks/use-metadata-hooks';
import Section from '../../layout/section/section';
import style from './TeamSection.module.scss';

interface TeamMemberProps {
  role: string;
  name: string;
  website?: string;
}

const RoleListItem = ({ role, name, website }: TeamMemberProps) => (
  <li className={style.RoleListItem}>
    <h6>{role}</h6>

    <ul>
      <li>{name}</li>
      {website && (
        <li style={{ fontSize: '0.7rem' }}>
          <OutLink className="underline black" href={website}>
            {website}
          </OutLink>
        </li>
      )}
    </ul>
  </li>
);

const TeamSection = () => {
  const { team } = useSiteMetadata();

  return (
    <Section className={style.Team}>
      <ul className={style.teamMembersList}>
        {team.map((member) => (
          <RoleListItem
            key={member.role}
            role={member.role}
            name={member.name}
            website={member.website}
          />
        ))}
      </ul>
    </Section>
  );
};

export default TeamSection;
